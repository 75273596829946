import React from "react"
import { Link } from 'gatsby'

import Layout from "../components/theme/layout"
import SEO from "../components/theme/seo"

import { Container, Row } from 'react-bootstrap'

export default function error() {
  return (
    <Layout>
    <SEO title="404: Not found" />
    <div className="masthead white paint">
      <Container>
          <Row><h1>404 : Error</h1></Row>
      </Container>
    </div>
    <Container>
      <Row>
        <h1>NOT FOUND</h1>
      </Row>
      <Row>
        <p>There is nothing here.</p>
      </Row>
      <Row className="margin-bottom">
        <p><Link to="/" className="full-button">Return home</Link></p>
      </Row>
    </Container>
    </Layout>
  )
}
